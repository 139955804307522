import 'whatwg-fetch';

/*
  Rails により生成され、メタタグに埋め込まれた X-CSRF-Token を用いて fetch でリクエストする
*/
const fetchWithToken = async (url: RequestInfo, init?: RequestInit | undefined) =>
  fetch(url, {
    credentials: 'same-origin',
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')!.getAttribute('content') as string,
    },
  });

export default fetchWithToken;
