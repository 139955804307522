import types from './types.es6';
import fetch from 'common/fetch';

export const succeedContact = () => ({ type: types.SUCCEED_CONTACT });
export const failedContact = () => ({ type: types.FAILED_CONTACT });

export const contact =
  ({ name, email, subject, contact }) =>
  (dispatch) => {
    fetch('/contact', {
      method: 'POST',
      body: JSON.stringify({
        name,
        contact: { email, subject, contact },
      }),
    })
      .then((response) => response.json())
      .then(({ status }) => {
        if (status === 'ok') {
          window.location.href = '/';
          dispatch(succeedContact());
        } else {
          dispatch(failedContact());
        }
      })
      .catch(() => {
        dispatch(failedContact());
      });
  };

export default {
  contact,
};
